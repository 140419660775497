.main {
    /*width: 100%;*/
    min-height: 100vh;
    /*background-image: url("https://trello-backgrounds.s3.amazonaws.com/SharedBackground/2560x1707/56b920e0cdf3d68edf638f57812446f5/photo-1513151233558-d860c5398176");*/

    /*
    background-image: url("/img/fondo.jpg");*/
    background-repeat: no-repeat;
    background-size: cover;

    background-color: transparent;
    background-image: linear-gradient(246deg,#1f3c6a,#178a9e 50%,#1bb4b7);


    /*display: grid;*/
    /*align-self: center;*/
    /*padding: 16px;*/
    /*grid-template-rows: 80px 1fr;*/

    /*overflow-x: scroll;*/
    /*overflow-y : scroll;*/
}
