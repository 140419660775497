.container {
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
@media (min-width: 768px) {
    .container {
        width: 100vw;
    }
}
@media (min-width: 992px) {
    .container {
        width: 98vw;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 90vw;
    }
}
