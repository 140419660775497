.couponItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    color: white;
    text-align: center;
    height: 100%;
}

.couponItemContent {
    padding: 1rem 0;
    align-items: center;
}

.couponItemContent > div:not(.btnContainer) {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnContainer{
    display : flex;
    flex-direction: column;
    align-items: stretch;
}

.img {
    height: 50px;
    width: 50px;
}

.promoBanner {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    padding: 8px 0px;
    background-color: #EA3546;;
    font-size: 24px;
    text-align: center;
}


.carousel {
    display: flex;
    overflow-x: auto;
    width: 980px;
}

.gridItem{
    color: #dcdfe5;
    text-shadow: 1px 1px black;
    /*font-size: 20px;*/
}
