.p-8 {
    padding: 8px;
}
.f-20 {
    font-size: 20px;
}
.f-24 {
    font-size: 24px;
}
.f-40 {
    font-size: 40px;
}

.mb-1{
    margin-bottom: 1rem;
}
.mt-1{
    margin-top: 1rem;
}
.mt-2{
    margin-top: 2rem;
}
.mt-3{
    margin-top: 3rem;
}
.mt-4{
    margin-top: 4rem;
}

.mr-1{
    margin-right: 1rem;
}

.mr-2{
    margin-right: 2rem;
}

.d-flex{
    display: flex;
}

.flex-row{
    flex-direction: row;
}
.flex-column{
    flex-direction: column;
}

.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}


.table th{
    font-size: 16px;
    padding: 0.8rem 0.5rem;
}

.table td{
    font-size: 16px;
    padding: 0.8rem 0.5rem;
}


.input{
    border-radius: 10px;
    padding: 12px;
    font-size: 24px;
}


.text-left{
    text-align: left;
}
