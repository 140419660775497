.btn{
    align-items: center;
    appearance: none;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#770319 0 -6px 0 inset;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding: 1.2rem 1.4rem 1.4rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 1.3rem;
    font-weight: bolder;
    outline: none !important;
    background-color: #cc0025;
    color:#FFFFFF;
    text-transform: uppercase;
    user-select: none;
  }
  
  .btn:focus {
      box-shadow: #770319 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #770319 0 -6px 0 inset;
  }
  
  .btn:hover {
  box-shadow: rgba(88, 85, 88, 0.1) 0 4px 8px, rgba(88, 85, 88, 0.1) 0 7px 13px -3px, #770319 0 -6px 0 inset;
  transform: translateY(-2px);
  }
  
  .btn:active {
  box-shadow: #770319 0 3px 7px inset;
  transform: translateY(2px);
  }
  
  .btn:disabled{
  opacity: 0.65;
  cursor: not-allowed;
  }
  
  .default{
      background: #cc0025;
      color : white;
      /*background: #830303;*/
  }
  
  .light{
      background: #e6eff5e6;
      color : rgba(27,28,29,0.9);
      box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#8d8f91e6 0 -6px 0 inset;
  }
  
  .default:active, .default:hover:enabled {
      background-color: #92041e;
  }
  
  .light:active, .light:hover:enabled {
      background-color: #c9cfd3e6;
  }
  
  .light:active {
      box-shadow: #8d8f91e6 0 3px 7px inset;
      transform: translateY(2px);
      }
  
  .light:hover {
      box-shadow: rgba(139, 138, 139, 0.1) 0 4px 8px, rgba(104, 103, 105, 0.1) 0 7px 13px -3px, #8d8f91e6 0 -6px 0 inset;
      transform: translateY(-2px);
   }
   .light:focus {
      box-shadow: #8d8f91e6 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #8d8f91e6 0 -6px 0 inset;
  }
  .compact{
      font-size: 16px;
      padding: 10px 8px 15px 8px;
      box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#6e6c6c 0 -6px 0 inset;
  }
  