@keyframes shake {
    /*0% { transform: skewX(-15deg); }*/
    /*5% { transform: skewX(15deg); }*/
    /*10% { transform: skewX(-15deg); }*/
    /*15% { transform: skewX(15deg); }*/
    /*20% { transform: skewX(0deg); }*/
    /*100% { transform: skewX(0deg); }*/

    /*0% { transform: scale(2); }*/
    /*5% { transform:  scale(4); }*/
    /*10% { transform:  scale(4); }*/
    /*15% { transform: scale(2); }*/
    /*20% { transform: scale(1); }*/
    /*100% { transform: scale(1); }*/

    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.4);
    }
    40% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}


.main {
    width: 100%;
    height: 100vh;
    background-image: url("https://trello-backgrounds.s3.amazonaws.com/SharedBackground/1536x2048/88cfc2608024d9d8fc1e3e4e953b6292/photo-1511298341002-15d91b5d09b7");
    /*background-image: url("https://trello-backgrounds.s3.amazonaws.com/SharedBackground/2560x1707/56b920e0cdf3d68edf638f57812446f5/photo-1513151233558-d860c5398176");*/
    /*background-image: url("/assets/img/fondo.jpg");*/
    background-repeat: no-repeat;
    background-size: cover;

    justify-content: center;
    align-items: center;
    align-content: center;
}

.logo {
    justify-items: center;
}

.title {
    color: white;
    font-size: 40px;
    text-align: center;
}

.box-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 480px;
    padding: 40px;
}

.input-dni {
    font-size: 32px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    text-transform: uppercase;
    font-weight:500;
    /*color: transparent;*/
    /*text-shadow: 0 0 0 gray;*/
}

.input-dni:focus{
    outline: none;
}

.toggle-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background: black;
  padding: 6px;
  gap: 6px;
  justify-content: center;
}

.toggle-button input[type="radio"] {
  display: none;
}

.toggle-button label {
  flex: 1;
  text-align: center;
  display: flex;
  background: #1d1d1d;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: bold;
  cursor:pointer;
  user-select: none;
}

.toggle-button label:active {
  transform: translateY(2px);
}

/* Estilo para cuando el input de tipo radio está seleccionado */
.toggle-button input[type="radio"]:checked + label {
  background-color: #cc0025;
  color: white;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #770319 0 -6px 0 inset;
}

/*banner de promociones*/
.promo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*background-color: red;*/

    /*background: rgb(0,0,0);*/
    /*background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(112,17,0,1) 100%);*/

    background: #830303;
    color: white;
    width: 400px;
    padding: 20px;
}

.promo-container h4, .promo-container h1 {
    animation: 5s shake infinite alternate;
}